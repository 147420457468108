// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-templates-policies-js": () => import("./../src/pages/templates/policies.js" /* webpackChunkName: "component---src-pages-templates-policies-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authorized-seller-application-js": () => import("./../src/pages/authorized-seller-application.js" /* webpackChunkName: "component---src-pages-authorized-seller-application-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("./../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-report-a-violation-js": () => import("./../src/pages/report-a-violation.js" /* webpackChunkName: "component---src-pages-report-a-violation-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

